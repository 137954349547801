import Flatpickr from 'stimulus-flatpickr'
import { useEventListener } from 'stimulus-library'

/**
 * Flatpickr has the concept of range selections, and has the concept
 * of invalid dates. Unfortunately, the classes applied to invalid dates in a
 * range selection make it difficult to style the case where an existing date
 * selection has become invalid because time has passed.
 *
 * This helper class can be used to fixup the calendar day elements so that they
 * can be styled more easily.
 */
class InvalidCalendarDayElement {

  constructor(htmlElement, dateRange, calendarDay) {
    this._htmlElement = htmlElement
    this._dateRange = dateRange
    this._calendarDay = calendarDay
    this._htmlElementClasses = this._htmlElement.className.split(' ')
  }

  fixupStyles() {
    if (this.startRangeDay()) {
      this.ensureStartRangeClassPresent()
      this.ensureSelectedClassPresent()
    }

    if (this.endRangeDay()) {
      this.ensureEndRangeClassPresent()
      this.ensureSelectedClassPresent()
    }
  }

  ensureEndRangeClassPresent() {
    if (!this._htmlElementClasses.includes('endRange')) {
      this._htmlElement.className += ' endRange'
    }
  }

  endRangeDay() {
    return this._calendarDay.valueOf() === this._dateRange[1]?.valueOf()
  }

  ensureSelectedClassPresent() {
    if (!this._htmlElementClasses.includes('selected')) {
      this._htmlElement.className += ' selected'
    }
  }

  ensureStartRangeClassPresent() {
    if (!this._htmlElementClasses.includes('startRange')) {
      this._htmlElement.className += ' startRange'
    }
  }

  startRangeDay() {
    return this._calendarDay.valueOf() === this._dateRange[0]?.valueOf()
  }

}

/**
 * The Stimulus-Flatpickr component (https://github.com/adrienpoly/stimulus-flatpickr)
 * is an excellent stimulus controller. However, it lacks a few features needed in this codebase:
 *
 *  - ESC keypress cancels the in-progress edit (and reverts the selected dates to the initial values)
 *  - Allow a single date to be selected in range mode (implying that the date is both the start and end values)
 *  - Enable a maximum duration of a date range
 *  - Style fixes for the Calendar day elements when the selected date range compasses invalid dates
 */
export default class FlatpickrController extends Flatpickr {
  static values = {
    freezeStartDate: Boolean,
    maxDays: Number
  }

  initialize() {
    this.config = {
      allowInvalidPreload: this.data.get('allowInvalidPreload') === 'true',
      defaultDate: '',
      locale: {
        rangeSeparator: ' - '
      }
    }
  }

  connect() {
    super.connect();
    this.detectInvalidSetup();

    // manually trigger on blur when value is changed (https://github.com/flatpickr/flatpickr/issues/2941)
    useEventListener(this, this.userInput(), 'blur', this.simulateUpdateValue.bind(this))

    // handle ESC keypress, when inputs have focus
    useEventListener(this, this.userInput(), 'keydown', this.cancelEditWithEscapeKey.bind(this))

    // handle ESC keypress, when the calendar control has focus
    // capture mode important, so that this event handler is fired *before* flatpickr's own keydown handler
    // (which is registered for bubble events)
    useEventListener(this, this.calendarContainerTarget, 'keydown', this.cancelEditWithEscapeKey.bind(this), {capture: true})
  }

  simulateUpdateValue(event) {
    // ignore change of focus within the calendar container
    if (event.relatedTarget && this.fp.calendarContainer.contains(event.relatedTarget)) {
      return
    }

    // don't trigger if the value hasn't changed
    if (this._previousValue === this.fp.input.value) {
      return
    }

    this.fp.updateValue(true)
  }

  detectInvalidSetup() {
    if (this.freezeStartDateValue) {
      if (this.fp.config.mode === 'single') {
        throw new Error('Configuration error: start date cannot be frozen in single mode')
      }

      if (this.fp.input.value === '') {
        throw new Error('Configuration error: cannot freeze start date when no date is selected')
      }
    }

  }

  cancelEditWithEscapeKey(event) {
    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      this.resetToPreviousValue()
      this.fp.close()
    }
  }

  previousDates() {
    return this._previousValue
      .split(' - ')
      .map(d => this.fp.parseDate(d, this.fp.config.dateFormat))
  }

  resetToPreviousValue() {
    this.fp.input.value = this._previousValue
    if (this.fp.altInput) {
      this.fp.altInput.value = this._previousAltValue
    }
    this.fp.setDate(this.previousDates())
  }

  allowSingleDateInRange(instance) {
    if (instance.config.mode === 'range' && instance.selectedDates.length === 1) {
      instance.setDate([instance.selectedDates[0], instance.selectedDates[0]])
    }
  }

  /**
   * Customize the creation of day elements in the Flatpickr calendar
   * @param selectedDates
   * @param dateStr
   * @param instance
   * @param calendarDayElement
   * @description When date ranges run into invalid dates, Flatpickr
   * doesn't include all the classes we want in order to style the range.
   * This callback ensures the classes are present we need to style invalid date ranges properly.
   */
  dayCreate(selectedDates, dateStr, instance, calendarDayElement) {
    if (instance.config.mode !== 'range') {
      return
    }

    const invalidDayHelper = new InvalidCalendarDayElement(
      calendarDayElement,
      selectedDates,
      calendarDayElement.dateObj
    )
    invalidDayHelper.fixupStyles()
  }

  ready(selectedDates, dateStr, instance) {
    this.allowSingleDateInRange(instance)

    window.dispatchEvent(new CustomEvent('flatpickr:ready', { detail: { instance } }))
  }

  open(selectedDates, dateStr, instance) {
    this._previousValue = instance.input.value
    this._previousAltValue = instance.altInput?.value
  }

  close(selectedDates, dateStr, instance) {
    this.allowSingleDateInRange(instance)
    this.loseFocus()
    if(this.hasMaxDaysValue) {
      this.useConfiguredMinMaxDates();
    }
  }

  useConfiguredMinMaxDates() {
    this.fp.set('maxDate', this.config.maxDate)
    this.fp.set('minDate', this.config.minDate)
  }

  checkMaxDateConstraint(selectedDates, dateStr, instance) {
    if (this.config.maxDate) {
      selectedDates.forEach(date => {
        if (date > instance.config.maxDate) {
          this.resetToPreviousValue()
          throw new Error(`${date} is later than max date ${instance.config.maxDate}`)
        }
      })
    }
  }

  checkMinDateConstraint(selectedDates, dateStr, instance) {
    if (this.config.minDate) {
      selectedDates.forEach(date => {
        if (date < instance.config.minDate) {
          this.resetToPreviousValue()
          throw new Error(`${date} is earlier than min date ${instance.config.minDate}`)
        }
      })
    }
  }

  checkEnabledDateConstraint(selectedDates, dateStr, instance) {
    if (this.config.enable) {
      selectedDates.forEach(date => {
        const isoDateString = date.toISOString().split('T')[0]
        if (!this.config.enable.includes(isoDateString)) {
          this.resetToPreviousValue()
          throw new Error(`${date} is not in the enabled date list`)
        }
      })
    }
  }

  checkDisabledDateConstraint(selectedDates, dateStr, instance) {
    if (this.config.disable) {
      selectedDates.forEach(date => {
        const isoDateString = date.toISOString().split('T')[0]
        if (this.config.disable.includes(isoDateString)) {
          this.resetToPreviousValue()
          throw new Error(`${date} is in the disabled date list`)
        }
      })
    }
  }

  singleDayEnabled() {
    if (this.config.enable) {
      return this.config.enable.length === 1
    } else if (this.config.minDate && this.config.maxDate) {
      let count = this.fp.config.maxDate - this.fp.config.minDate + 1
      if (this.config.disable) {
        count -= this.fp.config.disable.filter(date => date >= this.fp.config.minDate && date <= this.fp.config.maxDate).length
      }
      return count === 1
    }

    return false
  }

  valueUpdate(selectedDates, dateStr, instance) {
    try { this.checkMaxDateConstraint(selectedDates, dateStr, instance) }
    catch (error) { console.log(error); return }

    try { this.checkEnabledDateConstraint(selectedDates, dateStr, instance) }
    catch (error) { console.log(error); return }

    try { this.checkDisabledDateConstraint(selectedDates, dateStr, instance) }
    catch (error) { console.log(error); return }

    if (this.freezeStartDateValue) {
      const previousDates = this.previousDates()
      instance.setDate([previousDates[0], selectedDates[0]])
      instance.close()
      return
    }

    try { this.checkMinDateConstraint(selectedDates, dateStr, instance) }
    catch (error) { console.log(error); }
  }

  change(selectedDates, dateStr, instance) {
    if (this.singleDayEnabled() && selectedDates.length > 0) {
      instance.setDate([selectedDates[0]])
      instance.close();
      return
    }

    if (this.hasMaxDaysValue) {
      this.setMaxDateAfterSelectedStartDate(selectedDates);
    }
  }

  setMaxDateAfterSelectedStartDate(selectedDates) {
    if (selectedDates.length === 1) {
      const startDate = selectedDates[0]
      const maxDateFromStart = new Date(startDate)
      maxDateFromStart.setDate(startDate.getDate() + this.maxDaysValue - 1)
      if(this.config.maxDate && (new Date(this.config.maxDate) < maxDateFromStart)){
        this.fp.set('maxDate', this.config.maxDate)
      } else {
        this.fp.set('maxDate', maxDateFromStart)
      }
      this.fp.set('minDate', startDate)
    }
  }

  userInput() {
    return this.fp.altInput || this.fp.input
  }

  loseFocus() {
    this.userInput().blur()
  }
}
