import { Controller } from '@hotwired/stimulus'

/**
 * Add or remove a class to a target element when the controller `toggle` function is invoked.
 * This identifies the target element not using Stimulus targets, but rather using a CSS selector;
 * This controller can thus be used to toggle the class of any element on the page.
 */
export default class ToggleClassController extends Controller {
  static values = {
    targetSelector: String,
    class: String
  }

  toggle() {
    const target = document.querySelector(this.targetSelectorValue)
    target.classList.toggle(this.classValue)
  }
}