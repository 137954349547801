import { Turbo } from "@hotwired/turbo-rails"

Turbo.StreamActions.hide_dialog = function() {
  const target = this.getAttribute("target")
  document.querySelector(`sl-dialog#${target}`).hide()
}

Turbo.StreamActions.show_dialog = function() {
  const target = this.getAttribute("target")
  document.querySelector(`sl-dialog#${target}`).show()
}

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import TC from "@rolemodel/turbo-confirm"
TC.start({
  dialogSelector: '#turbo-confirm',
  activeClass: 'modal--active',
  acceptSelector: '#confirm--accept-btn',
  denySelector: '#confirm--deny-btn',
  showConfirmCallback: element =>  element.showModal(),
  contentSlots: {
    title: {
      contentAttribute: 'confirm-title',
      slotSelector: '#confirm--title'
    },
    body: {
      contentAttribute: 'confirm-message',
      slotSelector: '#confirm--body'
    },
    accept: {
      contentAttribute: 'confirm-accept',
      slotSelector: '#confirm--accept-btn'
    },
    cancel: {
      contentAttribute: 'confirm-deny',
      slotSelector: '#confirm--deny-btn'
    }
  }
})

Turbo.setProgressBarDelay(500)

// https://github.com/hotwired/turbo/issues/540 remove the following if this is resolved
const adapter = Turbo.navigator.delegate.adapter;
const session = Turbo.session;

let progressBarTimeout = null;
document.addEventListener('turbo:before-fetch-request', (event) => {
  const target = event.target;
  if (!(target instanceof HTMLElement)) {
    return;
  }
// debugger
  if ('true' === target.getAttribute('data-turbo-progress-bar')) {
    adapter.progressBar.setValue(0);
    progressBarTimeout = window.setTimeout(adapter.showProgressBar, session.progressBarDelay);
  }
});

document.addEventListener('turbo:before-fetch-response', () => {
  if (progressBarTimeout) {
    window.clearTimeout(progressBarTimeout);
    progressBarTimeout = null;

    adapter.progressBar.hide();
  }
});