
import { Controller } from '@hotwired/stimulus'
import { useMapReduce } from '../mixins/use_map_reduce'

import {
  elementValue,
  isPresent,
  setElementEnabled
} from '../html_element_functions'

const requiredElementClass = 'required'
const validatableElementClass = 'validatable'

/**
 * Form controller that supports required fields and validation. The form will not be submittable
 * until all required fields are populated, all validatable fields are valid, and at least one change is made to the form.
 *
 * This controller supports the following HTML inputs:
 * - input (text, password, number, email, url, search, checkbox, etc.)
 * - textarea
 * - select
 *
 * This controller does not currently support required fieldsets that contain checkboxes. For example, requiring a user
 * to select at least one checkbox in a group of checkboxes.
 */
export default class FormController extends Controller {
  static values = {
    name: String,
    submitButtonEnabled: {
      type: Boolean,
      default: false
    }
  }

  static get requiredElementClass() { return requiredElementClass }
  static get validatableElementClass() { return validatableElementClass }

  connect() {
    super.connect()
    this.detectInvalidSetup()
    this.setRequiredFieldsPresent(false)
    this.setInvalidFieldsPresent(true)
    this.setChangedFieldsPresent(false)
    this.initializeRequiredFieldsPipeline()
    this.initializeValidatableFieldsPipeline()
    this.initializeChangedFieldsPipeline()
    this.registerSubmitButtonClickHandler()
    this.setInitialButtonState()
  }

  registerSubmitButtonClickHandler() {
    this.element.addEventListener('turbo:submit-end', event => {
      document.activeElement.blur()
      this.submitButton.setAttribute("disabled", true)
      return true
    })
  }

  setInitialButtonState() {
    this.submitButtonEnabledValue ?
      this.submitButton.removeAttribute("disabled") :
      this.submitButton.setAttribute("disabled", true)
  }

  initializeRequiredFieldsPipeline() {
    this._requiredFieldsPipeline = useMapReduce(this, {
      name: 'requiredFields',
      input: this.requiredFields,
      map: elementValue,
      reduce: this.requiredConditionsSatisfied,
      output: this.setRequiredFieldsPresent
    })
  }

  initializeValidatableFieldsPipeline() {
    this._validatableFieldsPipeline = useMapReduce(this, {
      name: 'validatableFields',
      input: this.validatableFormInputs,
      map: this.isInvalidFormInput,
      reduce: invalidFieldStatus => invalidFieldStatus.some(result => result),
      output: this.setInvalidFieldsPresent
    })
  }

  initializeChangedFieldsPipeline() {
    this._changedFieldsPipeline = useMapReduce(this, {
      name: 'changedFields',
      input: () => this.formElements,
      events: ['input'],
      map: elementValue,
      reduce: this.changedValuesPresent,
      output: this.setChangedFieldsPresent
    })
  }

  updateCollection() {
    this._requiredFieldsPipeline.updateCollection()
    this._validatableFieldsPipeline.updateCollection()
    this._changedFieldsPipeline.updateCollection()
  }

  refreshFormState() {
    this._requiredFieldsPipeline.execute()
    this._validatableFieldsPipeline.execute()
    this._changedFieldsPipeline.execute()
  }

  detectInvalidSetup() {
    if (!this.hasNameValue) {
      throw new Error('Missing required configuration: name (value)')
    }
  }

  setRequiredFieldsPresent(value) {
    this._requiredFieldsPresent = value
    this.updateSubmitButton()
  }

  get requiredFieldsPresent() {
    return this._requiredFieldsPresent
  }

  setInvalidFieldsPresent(value) {
    this._invalidFieldsPresent = value
    this.updateSubmitButton()
  }

  get invalidFieldsPresent() {
    return this._invalidFieldsPresent
  }

  setChangedFieldsPresent(value) {
    if (value === null) {
      return
    }

    this._changedFieldsPresent = value
    this.updateSubmitButton()
  }

  get changedFieldsPresent() {
    return this._changedFieldsPresent
  }

  updateSubmitButton() {
    setElementEnabled(this.submitButton,
              this.requiredFieldsPresent && !this.invalidFieldsPresent && this.changedFieldsPresent)
  }

  get submitButton() {
    return this.element.elements.namedItem('submit')
  }

  requiredFields() {
    const requiredFieldSelector = `input.${requiredElementClass}${this.nameAttribute}, 
                                   select.${requiredElementClass}${this.nameAttribute}, 
                                   textarea.${requiredElementClass}${this.nameAttribute}`
    return this.formElements.filter(element => element.matches(requiredFieldSelector))
  }

  submitForm() {
    this.element.requestSubmit()
  }

  get nameAttribute() {
    // Rails form inputs are named to start with the form name, and use square brackets to indicate the
    // field name, e.g. <input name="form_name[field_name]" />
    return `[name^="${this.nameValue}["]`
  }

  requiredConditionsSatisfied(requiredFieldValues) {
    if (requiredFieldValues.length === 0) {
      return true
    }

    return requiredFieldValues.every(isPresent)
  }

  changedValuesPresent(currentValues) {
    if (this.valuesHaveBeenCached(currentValues)) {
      if (this._valuesHaveChanged) {
        return this._valuesHaveChanged
      }

      return this.inputValuesChangedFromCache(currentValues)
    }
    this._cachedElementValues = currentValues
    return null
  }

  valuesHaveBeenCached(currentValues) {
    return this._cachedElementValues && this._cachedElementValues.length === currentValues.length;
  }

  inputValuesChangedFromCache(currentValues) {
    const sortedCachedValues = this._cachedElementValues.sort()
    const sortedCurrentValues = currentValues.sort()

    const result = sortedCachedValues.some((element, index) => element !== sortedCurrentValues[index])
    if(result === true ){
      this._valuesHaveChanged = result
    }
    return result
  }

  isInvalidFormInput(input) {
    return input.getAttribute('aria-invalid') === 'true'
  }

  validatableFormInputs() {
    return this.formElements.filter(element => element.matches(`.${validatableElementClass}`))
  }

  get formElements() {
    return Array.from(this.element.elements)
  }
}