import { Controller } from '@hotwired/stimulus';

/**
 * Responsible for controlling the record iterator via keyboard events
 */
export default class RecordIteratorController extends Controller {

  connect() {
    this.nextButton = this.element.querySelector('.pagination-control__next')
    this.previousButton = this.element.querySelector('.pagination-control__previous')
  }

  next(event) {
    if (event.target instanceof HTMLInputElement || this.nextButtonDisabled()) {
      return
    }
    event.preventDefault();
    this.nextButton.click();
  }

  previous(event) {
    if (event.target instanceof HTMLInputElement || this.previousButtonDisabled()) {
      return
    }
    event.preventDefault();
    this.previousButton.click()
  }

  nextButtonDisabled() {
    return window.getComputedStyle(this.nextButton).pointerEvents === 'none'
  }

  previousButtonDisabled() {
    return window.getComputedStyle(this.nextButton).pointerEvents === 'none'
  }
}